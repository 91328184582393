body {
  font-family: 'Montserrat', sans-serif;
}

.bg-navbarBg {
  position: relative;
  background-size: cover;
  overflow: visible !important;
  box-shadow: 0px 0px 20px rgba(227, 228, 237, 0.37);
}

.bg-navbarBg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  z-index: 1;
}

.navbar-brand, .navbar-nav, .navbar-toggler {
  position: relative;
  z-index: 2;
}

.navbar {
  padding: 15px 0;
}

.navbar-brand img {
  filter: brightness(0) invert(1);
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

/* Fix for dropdown */
.dropdown-menu {
  z-index: 1030;
}
.caorusel-img {
  width: 20%;
  height: auto;
  display: flex;
  margin-bottom: 20px;
}

.header .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.header .overlay .subtitle {
  font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1200 - 320)));
  font-weight: 400;
  margin-bottom: 0;
  letter-spacing: 8px;
  word-spacing: 10px;
  text-align: center;
  opacity: 0.8;
}

.header .overlay .title {
  font-size: calc(30px + (70 - 30) * ((100vw - 320px) / (1200 - 320)));
  font-weight: bold;
}

.header .shape svg {
  position: absolute;
  bottom: -28px;
  left: 0;
  width: 100%;
  z-index: 9;
}

.header .shape svg path {
  fill: #fff;
}

@media (max-width: 1199.98px) {
  .header .shape svg {
    bottom: -15px;
  }
}

@media (max-width: 767.98px) {
  .header .shape svg {
    bottom: 0;
  }
  .section-title {
    font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1200 - 320)));
    padding-bottom: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  .section-subtitle {
    font-size: calc(13px + (13 - 13) * ((100vw - 320px) / (1200 - 320)));
    font-weight: 500;
    margin-bottom: 0px;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mouse-icon {
  border: 2px solid #fff;
  border-radius: 16px;
  height: 40px;
  width: 24px;
  display: block;
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  margin: auto;
}

.mouse-icon .wheel {
  position: relative;
  border-radius: 10px;
  background: #fff;
  width: 2px;
  height: 6px;
  top: 4px;
  margin-left: auto;
  margin-right: auto;
  -webkit-animation-name: drop;
  -webkit-animation-duration: 1.4s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  animation-name: drop;
  animation-duration: 1.4s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

.section-title {
  font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1200 - 320)));
  padding-bottom: 7px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-weight: 600;
}

.section-subtitle {
  font-size: calc(13px + (13 - 13) * ((100vw - 320px) / (1200 - 320)));
  font-weight: 500;
  margin-bottom: 0px;
  opacity: 0.9;
  display: flex;
  justify-content: left;
  align-items: center;
}

.product-card {
  position: relative;
  overflow: hidden;
  padding-top: 100%; 
}

.product-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.product-details {
  color: white;
  text-align: center;
  padding: 20px;
}

.product-title {
  margin-bottom: 10px;
}

.product-description {
  margin-bottom: 15px;
  font-size: 0.9em;
}

.product-card:hover .product-overlay {
  opacity: 1;
}

.product-card:hover .product-image {
  transform: scale(1.1);
  transition: 1s;
}
.global-clientele-bg {
  background-color: #343a40;
}

.flag-icon {
  font-size: 5rem;
}

.section-title, .section-subtitle {
  color: #fff;
}

.testimonial-carousel {
  position: relative;
  overflow: hidden;
}

.testimonial-slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.testimonial-card {
  flex: 0 0 100%;
  padding: 20px;
  text-align: center;
}

.testimonial-card.active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.testimonial-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.arrow {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 1;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.contact-section-wrapper {
  position: relative;
}

.contact-section-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); 
  z-index: 1;
}

.contact-section-content {
  position: relative;
  z-index: 2; 
}

.product-card {
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.card-img {
  width: 100%;
  height: 200px; 
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; 
}

.testimonial-section {
  padding: 3rem 1rem;
  background-color: #f9fafb;
}

@media (min-width: 640px) {
  .testimonial-section {
    padding: 4rem 1.5rem;
  }
}

@media (min-width: 1024px) {
  .testimonial-section {
    padding: 5rem 2rem;
  }
}

.container {
  max-width: 80rem;
  margin: 0 auto;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-text {
  text-align: center;
}

.subheading {
  font-size: 1.125rem;
  font-weight: 500;
  color: #4b5563;
}

.heading {
  margin-top: 1rem;
  font-size: 1.875rem;
  font-weight: 700;
  color: #111827;
}

@media (min-width: 640px) {
  .heading {
    font-size: 2.25rem;
  }
}

@media (min-width: 1280px) {
  .heading {
    font-size: 3rem;
  }
}

.testimonial-link {
  margin-top: 2rem;
  text-align: center;
  text-decoration: none;
}

@media (min-width: 768px) {
  .testimonial-link {
    margin-top: 4rem;
  }
}

.link-text {
  font-size: 1rem;
  font-weight: 700;
  color: #111827;
  text-decoration: none;
  border-bottom: 2px solid #111827;
  transition: all 0.2s;
}

.link-text:hover {
  border-color: #4b5563;
  color: #4b5563;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-top: 2.5rem;
  max-width: 32rem;
}

@media (min-width: 768px) {
  .testimonial-grid {
    grid-template-columns: repeat(3, 1fr);
    max-width: none;
    margin-top: 6rem;
  }
}

.testimonial-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  overflow: hidden;
}

.card-content {
  flex: 1;
  padding: 1.5rem;
}

@media (min-width: 1024px) {
  .card-content {
    padding: 2rem 1.75rem;
  }
}

.star-rating {
  display: flex;
}

.text-star {
  color: #FDB241;
}

.testimonial-quote {
  margin-top: 2rem;
  font-size: 1.125rem;
  line-height: 1.75;
  color: #111827;
}

.testimonial-author {
  padding: 1.5rem;
  background-color: #f3f4f6;
}

.author-image-container {
  display: flex;
  align-items: center;
}

.author-image {
  width: 2.75rem;
  height: 2.75rem;
  object-fit: cover;
  border-radius: 50%;
}

.author-info {
  margin-left: 1rem;
}

.author-name {
  font-size: 1rem;
  font-weight: 700;
  color: #111827;
}

.author-title {
  margin-top: 0.125rem;
  font-size: 0.875rem;
  color: #6b7280;
}

.testimonial-section {
  padding: 3rem 1rem;
  background-color: #f9fafb;
}

@media (min-width: 640px) {
  .testimonial-section {
    padding: 4rem 1.5rem;
  }
}

@media (min-width: 1024px) {
  .testimonial-section {
    padding: 5rem 2rem;
  }
}

.testimonial-container {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-header {
  text-align: center;
}

.testimonial-subheading {
  font-size: 1.125rem;
  font-weight: 500;
  color: #4b5563;
}

.testimonial-heading {
  margin-top: 1rem;
  font-size: 1.875rem;
  font-weight: 700;
  color: #111827;
}

@media (min-width: 640px) {
  .testimonial-heading {
    font-size: 2.25rem;
  }
}

@media (min-width: 1280px) {
  .testimonial-heading {
    font-size: 3rem;
  }
}

.testimonial-cta {
  margin-top: 2rem;
  text-align: center;
}

@media (min-width: 768px) {
  .testimonial-cta {
    margin-top: 4rem;
    order: 3;
  }
}

.testimonial-link {
  padding-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  color: #111827;
  border-bottom: 2px solid #111827;
  transition: all 0.2s;
}

.testimonial-link:hover {
  border-color: #4b5563;
  color: #4b5563;
}

.testimonial-cards-container {
  position: relative;
  margin-top: 2.5rem;
}

@media (min-width: 768px) {
  .testimonial-cards-container {
    margin-top: 6rem;
    order: 2;
  }
}

.testimonial-background {
  position: absolute;
  inset: 1rem -0.25rem;
  background: linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%);
  opacity: 0.3;
  filter: blur(1rem);
  border-radius: 1.5rem;
}

@media (min-width: 768px) {
  .testimonial-background {
    inset: -1.5rem -0.5rem;
  }
}

.testimonial-cards {
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 1vh;
  gap: 1.5rem;
  max-width: 32rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .testimonial-cards {
    grid-template-columns: repeat(3, 1fr);
    max-width: none;
  }
}

@media (min-width: 1024px) {
  .testimonial-cards {
    gap: 2.5rem;
  }
}

.testimonial-card {
  display: flex;
  border-radius: 1vh;
  flex-direction: column;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.testimonial-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 1.5rem;
}

@media (min-width: 1024px) {
  .testimonial-card-content {
    padding: 2rem 1.75rem;
  }
}

.testimonial-rating {
  display: flex;
}

.star-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #FDB241;
}

.testimonial-quote {
  flex-grow: 1;
}

.testimonial-quote p {
  font-size: 1rem;
  line-height: 1.75;
  color: #111827;
}

.testimonial-author {
  display: flex;
  border-radius: 1vh;
  align-items: center;
  margin-top: 1rem;
}

.testimonial-avatar {
  width: 2.75rem;
  height: 2.75rem;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 9999px;
}

.testimonial-author-info {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.testimonial-author-name {
  font-size: 1rem;
  font-weight: 700;
  color: #111827;
  margin: 0;
  line-height: 1.2;
}

.testimonial-author-role {
  font-size: 0.875rem;
  color: #6b7280;
  margin: 0;
  line-height: 1.2;
}

/* Preloader styles */
.preloader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent white */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(5px); /* This creates a blur effect on the background */
}

.loader {
  width: 40px;
  aspect-ratio: 1;
  position: relative;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  margin: -8px 0 0 -8px;
  width: 16px;
  aspect-ratio: 1;
  background: #FFF;
  animation:
    l1-1 2s infinite,
    l1-2 .5s infinite;
}

.loader:after {
  background:#185519;
  animation-delay: -1s,0s;
}

@keyframes l1-1 {
  0%   {top:0   ;left:0}
  25%  {top:100%;left:0}
  50%  {top:100%;left:100%}
  75%  {top:0   ;left:100%}
  100% {top:0   ;left:0}
}

@keyframes l1-2 {
   80%,100% {transform: rotate(0.5turn)}
}


/* For Tablets */
@media (max-width: 768px) {
  .flag-icon {
    width: 40px;
    height: auto;
  }
  
  .row > [class*='col-'] {
    padding: 5px;
  }
  .about-img{
    margin: 0px 20px 0px 20px;
  }
}
/* For Mobiles */
@media (max-width: 576px) {
  .flag-icon {
    font-size: 3rem;
    margin-left: 10px; 
    margin-right: 10px;
  }
  .about-img{
    margin: 0px 20px 0px 20px;
    display: flex;
  }
  .services-card {
    margin: 0px 20px 0px 20px;
  }
}